<template>
  <div class="form-subheader primary--text subtitle-2" v-on="$listeners">
    {{ title }}
  </div>
</template>

<script>
export default {
  name: 'FormSubheader',
  props: {
    title: {
      type: String,
      default: ''
    }
  }
};
</script>

<style lang="sass" scoped>
.form-subheader
  height: 48px
  line-height: 48px !important
</style>
